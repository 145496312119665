import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import styles from './carousel.module.css';
import Prev from './images/prev-btn.svg';
import Next from './images/next-btn.svg';
import Rotation from '../../components/images/rotation.svg';
import Zoom from '../../components/zoom/component';
import Pdf from './Pdf';
import { TransformComponent } from 'react-zoom-pan-pinch';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { thumborUrl, thumborBikeUrl } from '../../utils/url-constants';
import { isUserRoleBIkeQc, getFromLocalStorage } from '../../utils/helper';
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from '../../utils/app-constants';
import { sendEvent } from '../../utils/send-events';

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);
let isBikeQc = isUserRoleBIkeQc();

const Carousel = ({
  images,
  defaultIndex = 0,
  // resetCarouselByKeyConnect,
  carousel,
  setCurrentIndex,
  wheelZoomDisabled = true,
  height,
  currentMapper,
  inspectionDetails,
  resetInspectionDetailByKeyConnect,
  swipeIndex,
  rcImageCount,
  additionalImageCount,
  insuranceImageCount,
  showInsuranceCount = false,
  refurbishment = false,
  fetchRestrictImagesHandler,
  hasGAEvent = false,
  addGAEvents = {}
}) => {
  // const cdnBaseUrl = isBikeQc ? thumborBikeUrl() : thumborUrl();
  const cdnBaseUrl = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL)
    ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL)
    : thumborUrl();
  const existingMapper = !!currentMapper
    ? inspectionDetails[currentMapper]
    : null;
  const { defaultPositionY, defaultPositionX, defaultScale } = carousel;
  const [swipableIndex, setSwipableIndex] = useState(defaultIndex);
  const [rotateDeg, setRotateDeg] = useState(0);
  const brokenUrl =
    'https://icon-icons.com/icons2/2036/PNG/512/blank_file_page_empty_document_icon_124196.png';

  // const {imagequalityParam } = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) : ""; //inspectionDetails;
  const imagequalityParam = !!getFromLocalStorage(
    LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM,
  )
    ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM)
    : ''; //inspectionDetails;

  // useEffect(() => {
  //     setSwipableIndex(0);
  //
  //
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.stringify(images)]);

  useEffect(() => {
    setCurrentIndex(swipableIndex);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipableIndex]);

  const onChangeIndex = index => {
    setSwipableIndex(index);
  };

  // const transformComponent = ()=>{
  //     return <TransformComponent >
  //         <img key={index} src={`${cdnBaseUrl}${url}`} alt={title} />
  //     </TransformComponent>;
  // };

  const onClickNext = () => {
    // resetPositions();
    const nextIndex =
      swipableIndex + 1 === images.length ? 0 : swipableIndex + 1;
    if (!!existingMapper && !!existingMapper[nextIndex].rotation) {
      setRotateDeg(existingMapper[nextIndex].rotation);
    } else {
      setRotateDeg(0);
    }
    setSwipableIndex(nextIndex);
  };

  const onClickPrevious = () => {
    // resetPositions();
    const previousIndex =
      swipableIndex - 1 === -1 ? images.length - 1 : swipableIndex - 1;
    if (!!existingMapper && !!existingMapper[previousIndex].rotation) {
      setRotateDeg(existingMapper[previousIndex].rotation);
    } else {
      setRotateDeg(0);
    }
    setSwipableIndex(previousIndex);
  };

  const rotate = directtion => {
    let newRotation = rotateDeg;
    if (directtion === 'R') {
      newRotation = rotateDeg + 90;
      if (newRotation >= 360) {
        newRotation = -360;
      }
    } else {
      newRotation = rotateDeg - 90;
      if (newRotation <= -360) {
        newRotation = 360;
      }
    }
    setRotateDeg(newRotation);
    images[swipableIndex].rotation = newRotation;

    if (!!existingMapper) {
      resetInspectionDetailByKeyConnect(currentMapper, images);
    }
  };

  // const resetPositions = ()=>{
  //     resetCarouselByKeyConnect("defaultScale",1);
  //     resetCarouselByKeyConnect("defaultPositionX",200);
  //     resetCarouselByKeyConnect("defaultPositionX",100);
  // };

  const setSwipeIndexForAdditional = setIndex => {
    setSwipableIndex(setIndex);
  };

  const handleRightClick = (e, url) => {
    e.preventDefault();
    hasGAEvent && sendEvent(addGAEvents(url));
  }
  const onFetchRestrictImages = () => {
    if (!inspectionDetails.isRestrictedImage) {
      fetchRestrictImagesHandler();
    }
  };
  const containsPdf = url => {
    return /\.pdf/i.test(url);
  };
  return (
    <div className={styles.carouselWrapper} onClick={onFetchRestrictImages}>
      {(rcImageCount > 0 || additionalImageCount > 0) && (
        <div className={styles.additionalWrapper}>
          <p
            onClick={() => setSwipeIndexForAdditional(0)}
            className={styles.rc}>
            rc - <span>{rcImageCount}</span>
          </p>
          <p
            onClick={() => setSwipeIndexForAdditional(5)}
            className={styles.additional}>
            additional - <span>{additionalImageCount}</span>
          </p>
        </div>
      )}
      {showInsuranceCount && (
        <div className={styles.additionalWrapper}>
          <p
            onClick={() => setSwipeIndexForAdditional(0)}
            className={styles.insurance}>
            Insurance - <span>{insuranceImageCount}</span>
          </p>
        </div>
      )}
      <BindKeyboardSwipeableViews
        index={swipableIndex}
        onChangeIndex={onChangeIndex}>
        {images &&
          images.map(({ url, title, isPdf }, index) => {
            const isPDFFile = containsPdf(url);
            return (
              <div key={`img_${index}`} className={styles.imageWrapper} onContextMenu={(e) => handleRightClick(e, url)}>
                {refurbishment && (
                  <p className={styles.refurbishImageTitle}>{title}</p>
                )}
                <TransformWrapper
                  defaultScale={1}
                  options={[]}
                  wheel={{ disabled: wheelZoomDisabled }}>
                  {({ zoomIn, zoomOut, ...rest }) => (
                    <div
                      className={`${styles.imageMainWrapper} ${
                        isPDFFile ? 'cursor-pointer' : ''
                      }`}>
                      <TransformComponent>
                        {isPDFFile ? (
                          <Pdf url={url} />
                        ) : (
                          <img
                            style={{ transform: `rotate(${rotateDeg}deg)` }}
                            key={index}
                            // src={
                            //   url
                            //     ? `${cdnBaseUrl}${url ||
                            //         ''}${imagequalityParam}`
                            //     : brokenUrl
                            // }
                            src={url}
                            alt={title}
                          />
                        )}
                      </TransformComponent>

                      <div className={styles.zoomWrapper}>
                        <Zoom zoomIn={zoomIn} zoomOut={zoomOut} />
                      </div>
                    </div>
                  )}
                </TransformWrapper>
                {!refurbishment && <p className={styles.imgCaption}>{title}</p>}
              </div>
            );
          })}
      </BindKeyboardSwipeableViews>
      <div className={styles.nextPrev}>
        <button className={styles.rotationImg} onClick={() => rotate('L')}>
          <img src={Rotation} alt="rotation left" />
        </button>
        <button className={styles.rotation} onClick={() => rotate('R')}>
          <img src={Rotation} alt="rotation right" />
        </button>
        <div>
          <button
            // disabled={swipableIndex - 1 === -1}
            onClick={() => {
              onClickPrevious();
            }}
            className={styles.prev}>
            <img src={Prev} alt="Previous" />
          </button>
          <button
            // disabled={swipableIndex + 1 === images.length}
            onClick={() => {
              onClickNext();
            }}
            className={styles.next}>
            <img src={Next} alt="Next" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
