/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import InspectionHeader from "../../components/inspection-header/";
import InspectionReviewInfo from "../../components/inspection-review-info/";
import Carousel from "../../components/carousel";
import styles from "./inspection-review.module.css";
import ImageCheckbox from "../../components/image-checkbox";
import InspectionReviewDocuments from "../../components/inspection-review-documents";
import ErrorList from "../../components/error-list";
import InspectionReviewInsurance from "../../components/inspection-review-insurance";
import InspectionReviewCarDetails from "../../components/inspection-review-car-details";
import InspectionReviewExterior from "../../components/inspection-review-exterior";
import InspectionReviewTyres from "../../components/inspection-review-tyres";
import InspectionReviewInterior from "../../components/inspection-review-interior";
import ReInspection from "../../components/re-inspection";
import InspectionReviewFooter from "../../components/inspection-review-footer";
import InspectionReviewRc from "../../components/inspection-review-rc";
import DataInformationMessage from "../../components/data-information-message";
import MultipleCarousel from "../../components/multiple-carousel";
import AdditionalImages from "../../components/additional-images";
import { InspectionService } from "../../service";
import { formatDate, getFromLocalStorage, getFullMonthName, isValidDate, removeSpecialCharAndSpace } from "../../utils/helper";
import { getVahaanDataMoc } from "../../utils/mocApi";
import Audit from "../../components/audit-version";
import ReInspectionSentMail from '../../components/sent-mail';
import RejectionTable from "../../components/rejection-table";
import Loader from "../../components/loader";
import { useRejectionTable } from "../../components/hooks";
import Popup from "../../components/popup";
import CustomerDocumentPopup from "../../components/customer-document-popup";
import { sendEvent } from "../../utils/send-events";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
const InspectionReview = ({
    fetchInspectionDetailConnect,
    match,
    inspectionDetails,
    history,
    resetInspectionDetailByKeyConnect,
    resetInspectionDetailConnect
}) => {
    const { params: { carId } } = match;
    const {
        version,
        isLoading,
        error,
        selectedVersion,
        appointmentId,
        parivahanWebsite,
        vahanWebsite,
        vahanSms,
        documentsMapper,
        makeModelVariantMapper,
        insuranceMapper,
        isDocumentPopup=false, 
    } = inspectionDetails;
    const rejectionData = useRejectionTable();
    const [rejectionDataList, setRejectionDataList] = useState([]);
    const [isReviewSubmitting, setIsReviewSubmitting] = useState(false);
    const [isDocumentPopupOpen, setIsDocumentPopupOpen] = useState(isDocumentPopup);
    const inspectionStatus = selectedVersion > -1 && history.location && history.location.state && 
    history.location.state.inspectionStatus || inspectionDetails.inspectionStatus;
    const inputEl = useRef(null);
    const docRef = useRef(null);
    const intRef = useRef(null);
    const sumRef = useRef(null);
    const [reInspectionEmailLog, setReInspectionEmailLog] = useState([]);
    useEffect(() => {
        if(appointmentId) {
            sendEvent({
                event:'home_page',
                eventCategory:'insp_qc_panel',
                eventAction:'documents',
                eventLabel:`${appointmentId}|${getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL)}`
            })
            InspectionService.getReinspectionEmailLogData(appointmentId).then(response => {
                const { data: {detail}} = response;
                setReInspectionEmailLog(detail);
            }).catch(error => {
                console.log('error', error);
            });
        }
    }, [appointmentId]);

    useEffect(() => {
        return () => {
            resetInspectionDetailConnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(carId) {
            const payload = {
                "carId": carId
            };
            InspectionService.setReviewStartTime(payload);
        }
    }, [carId]);

    useEffect(() => {
        //No of owners

        if (vahanSms) {
            const noOfOwnersIndex = documentsMapper.findIndex(item => item.key == "ownerNumber");
            // if (vahanSms && (vahanSms.noOfOwners || vahanSms.noOfOwners == 0)) {
            //     if (documentsMapper[noOfOwnersIndex].value == vahanSms.noOfOwners) {
            //         documentsMapper[noOfOwnersIndex].vaahanFlag = "yes";
            //         documentsMapper[noOfOwnersIndex].vahanSms = `${vahanSms.noOfOwners}`;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[noOfOwnersIndex].vaahanFlag = "no";
            //         documentsMapper[noOfOwnersIndex].vahanSms = `${vahanSms.noOfOwners}`;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

        }

        if (vahanSms || vahanWebsite || parivahanWebsite) {
            //Registration Number
            let registrationNotMatch = false;
            const registrationNumberIndex = documentsMapper.findIndex(item => item.key == "registrationNumber");
            // if (vahanSms && vahanSms.registrationNumber) {
            //     if ((vahanSms.registrationNumber == documentsMapper[registrationNumberIndex].value)) {
            //         documentsMapper[registrationNumberIndex].vaahanFlag = "yes";
            //         documentsMapper[registrationNumberIndex].vahanSms = vahanSms.registrationNumber;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[registrationNumberIndex].vaahanFlag = "no";
            //         registrationNotMatch = true;
            //         documentsMapper[registrationNumberIndex].vahanSms = vahanSms.registrationNumber;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            // if (parivahanWebsite && (parivahanWebsite.registrationNumber)) {
            //     if ((parivahanWebsite.registrationNumber == documentsMapper[registrationNumberIndex].value)) {
            //         documentsMapper[registrationNumberIndex].vaahanFlag = registrationNotMatch ? "no" : "yes";
            //         documentsMapper[registrationNumberIndex].parivahanWebsite = parivahanWebsite.registrationNumber;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[registrationNumberIndex].vaahanFlag = "no";
            //         registrationNotMatch = true;
            //         documentsMapper[registrationNumberIndex].parivahanWebsite = parivahanWebsite.registrationNumber;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }
            // if (vahanWebsite && (vahanWebsite.registrationNumber)) {
            //     if ((vahanWebsite.registrationNumber == documentsMapper[registrationNumberIndex].value)) {
            //         documentsMapper[registrationNumberIndex].vaahanFlag = registrationNotMatch ? "no" : "yes";
            //         documentsMapper[registrationNumberIndex].vahanWebsite = vahanWebsite.registrationNumber;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[registrationNumberIndex].vaahanFlag = "no";
            //         documentsMapper[registrationNumberIndex].vahanWebsite = vahanWebsite.registrationNumber;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            //Fitness Upto
            let fitnessUptoNotMatch = false;
            const fitnessUptoIndex = documentsMapper.findIndex(item => item.key == "fitnessUpto");

            // if (vahanSms && vahanSms.fitnessUpto) {
            //     if ((formatDate(vahanSms.fitnessUpto) == documentsMapper[fitnessUptoIndex].value)) {
            //         documentsMapper[fitnessUptoIndex].vaahanFlag = "yes";
            //         documentsMapper[fitnessUptoIndex].vahanSms = vahanSms.fitnessUpto;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[fitnessUptoIndex].vaahanFlag = "no";
            //         fitnessUptoNotMatch = true;
            //         documentsMapper[fitnessUptoIndex].vahanSms = vahanSms.fitnessUpto;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            // if (parivahanWebsite && (parivahanWebsite.fitnessUpto)) {
            //     if ((formatDate(parivahanWebsite.fitnessUpto) == documentsMapper[fitnessUptoIndex].value)) {
            //         documentsMapper[fitnessUptoIndex].vaahanFlag = fitnessUptoNotMatch ? "no" : "yes";
            //         documentsMapper[fitnessUptoIndex].parivahanWebsite = parivahanWebsite.fitnessUpto;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[fitnessUptoIndex].vaahanFlag = "no";
            //         fitnessUptoNotMatch = true;
            //         documentsMapper[fitnessUptoIndex].parivahanWebsite = parivahanWebsite.fitnessUpto;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            // if (vahanWebsite && (vahanWebsite.fitnessUpto)) {
            //     if ((formatDate(vahanWebsite.fitnessUpto) == documentsMapper[fitnessUptoIndex].value)) {
            //         documentsMapper[fitnessUptoIndex].vaahanFlag = fitnessUptoNotMatch ? "no" : "yes";
            //         documentsMapper[fitnessUptoIndex].vahanWebsite = vahanWebsite.fitnessUpto;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[fitnessUptoIndex].vaahanFlag = "no";
            //         documentsMapper[fitnessUptoIndex].vahanWebsite = vahanWebsite.fitnessUpto;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            //Registration Year
            const registrationYearIndex = documentsMapper.findIndex(item => item.key == "registrationYear");
            // if (vahanWebsite && ((vahanWebsite.registrationYear) == documentsMapper[registrationYearIndex].value) ||
            //     parivahanWebsite && ((parivahanWebsite.registrationYear) == documentsMapper[registrationYearIndex].value)) {
            //     documentsMapper[registrationYearIndex].vaahanFlag = true;
            //     resetInspectionDetailByKeyConnect("documentsMapper",documentsMapper);
            // }
            let registrationYearNoMatch = false;
            // if (parivahanWebsite && (parivahanWebsite.registrationYear)) {
            //     if ((parivahanWebsite.registrationYear == documentsMapper[registrationYearIndex].value)) {
            //         documentsMapper[registrationYearIndex].vaahanFlag = "yes";
            //         documentsMapper[registrationYearIndex].parivahanWebsite = parivahanWebsite.registrationYear;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[registrationYearIndex].vaahanFlag = "no";
            //         registrationYearNoMatch = true;
            //         documentsMapper[registrationYearIndex].parivahanWebsite = parivahanWebsite.registrationYear;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            // if (vahanWebsite && (vahanWebsite.registrationYear)) {
            //     if ((vahanWebsite.registrationYear == documentsMapper[registrationYearIndex].value)) {
            //         documentsMapper[registrationYearIndex].vaahanFlag = registrationYearNoMatch ? "no" : "yes";
            //         documentsMapper[registrationYearIndex].vahanWebsite = vahanWebsite.registrationYear;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[registrationYearIndex].vaahanFlag = "no";
            //         fitnessUptoNotMatch = true;
            //         documentsMapper[registrationYearIndex].vahanWebsite = vahanWebsite.registrationYear;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            //Registration Month
            let registrationMonthMatch = false;
            const registrationMonthIndex = documentsMapper.findIndex(item => item.key == "registrationMonth");
            // if (vahanSms && vahanSms.registrationMonth) {
            //     if ((getFullMonthName(vahanSms.registrationMonth) == documentsMapper[registrationMonthIndex].value)) {
            //         documentsMapper[registrationMonthIndex].vaahanFlag = "yes";
            //         documentsMapper[registrationMonthIndex].vahanSms = getFullMonthName(vahanSms.registrationMonth);
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[registrationMonthIndex].vaahanFlag = "no";
            //         registrationMonthMatch = true;
            //         documentsMapper[registrationMonthIndex].vahanSms = getFullMonthName(vahanSms.registrationMonth);
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            // if (parivahanWebsite && (parivahanWebsite.registrationMonth)) {
            //     if ((getFullMonthName(parivahanWebsite.registrationMonth) == documentsMapper[registrationMonthIndex].value)) {
            //         documentsMapper[registrationMonthIndex].vaahanFlag = registrationMonthMatch ? "no" : "yes";
            //         documentsMapper[registrationMonthIndex].parivahanWebsite = getFullMonthName(parivahanWebsite.registrationMonth);
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[registrationMonthIndex].vaahanFlag = "no";
            //         registrationMonthMatch = true;
            //         documentsMapper[registrationMonthIndex].parivahanWebsite = getFullMonthName(parivahanWebsite.registrationMonth);
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            // if (vahanWebsite && (vahanWebsite.registrationMonth)) {
            //     if ((getFullMonthName(vahanWebsite.registrationMonth) == documentsMapper[registrationMonthIndex].value)) {
            //         documentsMapper[registrationMonthIndex].vaahanFlag = registrationMonthMatch ? "no" : "yes";
            //         documentsMapper[registrationMonthIndex].vahanWebsite = getFullMonthName(vahanWebsite.registrationMonth);
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[registrationMonthIndex].vaahanFlag = "no";
            //         registrationMonthMatch = true;
            //         documentsMapper[registrationMonthIndex].vahanWebsite = getFullMonthName(vahanWebsite.registrationMonth);
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            //Fuel Type
            const fuelTypeIndex = makeModelVariantMapper.findIndex(item => item.key == "fuelType");
            let fuelTypeNoMatch = false;
            // if (vahanSms && ((vahanSms.fuelType) === makeModelVariantMapper[fuelTypeIndex].value) ||
            //     vahanWebsite && ((vahanWebsite.fuelType) === makeModelVariantMapper[fuelTypeIndex].value) ||
            //     parivahanWebsite && ((parivahanWebsite.fuelType) === makeModelVariantMapper[fuelTypeIndex]).value) {
            //     makeModelVariantMapper[fuelTypeIndex].vaahanFlag = true;
            //     resetInspectionDetailByKeyConnect("makeModelVariantMapper",makeModelVariantMapper);
            // }

            if (vahanSms && vahanSms.fuelType) {
                if ((vahanSms.fuelType == documentsMapper[fuelTypeIndex].value)) {
                    makeModelVariantMapper[fuelTypeIndex].vaahanFlag = "yes";
                    makeModelVariantMapper[fuelTypeIndex].vahanSms = vahanSms.fuelType;
                    resetInspectionDetailByKeyConnect("makeModelVariantMapper", makeModelVariantMapper);
                }
                else {
                    makeModelVariantMapper[fuelTypeIndex].vaahanFlag = "no";
                    fuelTypeNoMatch = true;
                    makeModelVariantMapper[fuelTypeIndex].vahanSms = vahanSms.fuelType;
                    resetInspectionDetailByKeyConnect("makeModelVariantMapper", makeModelVariantMapper);
                }
            }

            if (parivahanWebsite && (parivahanWebsite.fuelType)) {
                if ((parivahanWebsite.fuelType == documentsMapper[fuelTypeIndex].value)) {
                    makeModelVariantMapper[fuelTypeIndex].vaahanFlag = fuelTypeNoMatch ? "no" : "yes";
                    makeModelVariantMapper[fuelTypeIndex].parivahanWebsite = parivahanWebsite.fuelType;
                    resetInspectionDetailByKeyConnect("makeModelVariantMapper", makeModelVariantMapper);
                }
                else {
                    makeModelVariantMapper[fuelTypeIndex].vaahanFlag = "no";
                    fuelTypeNoMatch = true;
                    makeModelVariantMapper[fuelTypeIndex].parivahanWebsite = parivahanWebsite.fuelType;
                    resetInspectionDetailByKeyConnect("makeModelVariantMapper", makeModelVariantMapper);
                }
            }

            if (vahanWebsite && (vahanWebsite.fuelType)) {
                if ((vahanWebsite.fuelType == documentsMapper[fuelTypeIndex].value)) {
                    makeModelVariantMapper[fuelTypeIndex].vaahanFlag = fuelTypeNoMatch ? "no" : "yes";
                    makeModelVariantMapper[fuelTypeIndex].vahanWebsite = vahanWebsite.fuelType;
                    resetInspectionDetailByKeyConnect("makeModelVariantMapper", makeModelVariantMapper);
                }
                else {
                    makeModelVariantMapper[fuelTypeIndex].vaahanFlag = "no";
                    makeModelVariantMapper[fuelTypeIndex].vahanWebsite = vahanWebsite.fuelType;
                    fuelTypeNoMatch = true;
                    resetInspectionDetailByKeyConnect("makeModelVariantMapper", makeModelVariantMapper);
                }
            }

            //Under Hypothecation
            const underHypothecationIndex = documentsMapper.findIndex(item => item.key == "isUnderHypothecation");
            // if (vahanSms && ((vahanSms.isUnderHypothecation) === documentsMapper[underHypothecationIndex].value) ||
            //     vahanWebsite && ((vahanWebsite.isUnderHypothecation) === documentsMapper[underHypothecationIndex].value)) {
            //     documentsMapper[underHypothecationIndex].vaahanFlag = true;
            //     resetInspectionDetailByKeyConnect("documentsMapper",documentsMapper);
            // }
            if (vahanSms && vahanWebsite) {
                const inspection_data = documentsMapper[underHypothecationIndex].value ?
                    `${documentsMapper[underHypothecationIndex].value}` : "";

                const isFinancierNameVaahanWebsite = Object.keys(vahanWebsite).find(item => item.match(/financ/i));
                // const isvahanSms = vahanSms[0] ? Object.keys(vahanSms[0]).find(item => item.match(/financ/i)) : null;
                const isvahanSms = vahanSms[0] ? vahanSms[0].hypothecation : null;
                // const hypoVaahanSms = `${vahanSms.isUnderHypothecation}`;
                // const hypoVaahanWebsite = `${vahanWebsite.isUnderHypothecation}`;

                // if (inspection_data) {
                //     //case:1
                //     if (inspection_data.match(/Yes/i)) {
                //         if (inspection_data.match(/Yes/i) && (isFinancierNameVaahanWebsite || (isvahanSms && isvahanSms === 1))) {
                //             documentsMapper[underHypothecationIndex].vaahanFlag = "yes";
                //         } else {
                //             documentsMapper[underHypothecationIndex].vaahanFlag = "no";

                //         }
                //     } else {
                //         //case 2:
                //         if (inspection_data.match(/No/i) && !isFinancierNameVaahanWebsite && (!isvahanSms || isvahanSms === 0)) {
                //             documentsMapper[underHypothecationIndex].vaahanFlag = "yes";
                //         } else {
                //             documentsMapper[underHypothecationIndex].vaahanFlag = "no";
                //         }
                //     }
                //     // documentsMapper[underHypothecationIndex].vahanSms = vahanSms["Finance:"] ? `${vahanSms["Finance:"]}` : "";
                //     documentsMapper[underHypothecationIndex].vahanSms = vahanSms[0] ? `${vahanSms[0].hypothecationBank}` : "";
                //     documentsMapper[underHypothecationIndex].vahanWebsite = vahanWebsite["Financier's Name:"] ? `${vahanWebsite["Financier's Name:"]}` : "";
                //     resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);

                // }
            }

            //Customer Name
            const customerNameIndex = documentsMapper.findIndex(item => item.key == "customerName");
            let mapperCustomerName = !!documentsMapper[customerNameIndex].value ? documentsMapper[customerNameIndex].value : "";
            let customerNoMatch = false;
            // if (vahanSms && ((vahanSms.customerName) === documentsMapper[customerNameIndex].value) ||
            //     vahanWebsite && ((vahanWebsite.customerName) === documentsMapper[customerNameIndex].value) ||
            //     parivahanWebsite && ((parivahanWebsite.customerName) === documentsMapper[customerNameIndex]).value) {
            //     documentsMapper[customerNameIndex].vaahanFlag = true;
            //     resetInspectionDetailByKeyConnect("documentsMapper",documentsMapper);
            // }

            // if (vahanSms && vahanSms.customerName) {
            //     let vahanCustomerName = !!vahanSms.customerName ? vahanSms.customerName : "";
            //     if ((removeSpecialCharAndSpace(vahanCustomerName).toLowerCase() == removeSpecialCharAndSpace(mapperCustomerName).toLowerCase())) {
            //         documentsMapper[customerNameIndex].vaahanFlag = "yes";
            //         documentsMapper[customerNameIndex].vahanSms = vahanSms.customerName;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[customerNameIndex].vaahanFlag = "no";
            //         customerNoMatch = true;
            //         documentsMapper[customerNameIndex].vahanSms = vahanSms.customerName;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            // if (parivahanWebsite && (parivahanWebsite.customerName)) {
            //     let vahanCustomerName = !!parivahanWebsite.customerName ? parivahanWebsite.customerName : "";
            //     if ((removeSpecialCharAndSpace(vahanCustomerName).toLowerCase() == removeSpecialCharAndSpace(mapperCustomerName).toLowerCase())) {
            //         documentsMapper[customerNameIndex].vaahanFlag = customerNoMatch ? "no" : "yes";
            //         documentsMapper[customerNameIndex].parivahanWebsite = parivahanWebsite.customerName;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[customerNameIndex].vaahanFlag = "no";
            //         customerNoMatch = true;
            //         documentsMapper[customerNameIndex].parivahanWebsite = parivahanWebsite.customerName;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

            // if (vahanWebsite && (vahanWebsite.customerName)) {
            //     let vahanCustomerName = !!vahanWebsite.customerName ? vahanWebsite.customerName : "";
            //     if ((removeSpecialCharAndSpace(vahanCustomerName).toLowerCase() == removeSpecialCharAndSpace(mapperCustomerName).toLowerCase())) {
            //         documentsMapper[customerNameIndex].vaahanFlag = customerNoMatch ? "no" : "yes";
            //         documentsMapper[customerNameIndex].vahanWebsite = vahanWebsite.customerName;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[customerNameIndex].vaahanFlag = "no";
            //         customerNoMatch = true;
            //         documentsMapper[customerNameIndex].vahanWebsite = vahanWebsite.customerName;
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }
            //Insurance Expiry Date
            const insuranceExpiryIndex = insuranceMapper.findIndex(item => item.key == "insuranceExpiry");
            // if (parivahanWebsite && (formatDate(parivahanWebsite.insuranceExpiry) === insuranceMapper[insuranceExpiryIndex]).value) {
            //     insuranceMapper[fitnessUptoIndex].vaahanFlag = true;
            //     resetInspectionDetailByKeyConnect("insuranceMapper",insuranceMapper);
            // }
            if (parivahanWebsite && (parivahanWebsite.insuranceExpiry)) {
                if ((formatDate(parivahanWebsite.insuranceExpiry) == insuranceMapper[insuranceExpiryIndex].value)) {
                    insuranceMapper[insuranceExpiryIndex].vaahanFlag = "yes";
                    insuranceMapper[insuranceExpiryIndex].parivahanWebsite = parivahanWebsite.insuranceExpiry;
                    resetInspectionDetailByKeyConnect("insuranceMapper", insuranceMapper);
                }
                else {
                    insuranceMapper[insuranceExpiryIndex].vaahanFlag = "no";
                    insuranceMapper[insuranceExpiryIndex].parivahanWebsite = parivahanWebsite.insuranceExpiry;
                    resetInspectionDetailByKeyConnect("insuranceMapper", insuranceMapper);
                }
            }

            //Road Tax Paid:
            const roadTaxPaidIndex = documentsMapper.findIndex(item => item.key == "roadTaxPaid");
            const roadTaxDateValidityIndex = documentsMapper.findIndex(item => item.key == "roadTaxDateValidity");
            let roadTaxNoMatch = false;
            // if (vahanWebsite && vahanWebsite["MV Tax upto:"]) {
            //     if (((vahanWebsite["MV Tax upto:"].match(/life/i) || vahanWebsite["MV Tax upto:"].match(/ltt/i) || vahanWebsite["MV Tax upto:"].match(/ott/i)) &&
            //         (documentsMapper[roadTaxPaidIndex].value.match(/ott/i) || documentsMapper[roadTaxPaidIndex].value.match(/ltt/i)))) {
            //         documentsMapper[roadTaxPaidIndex].vaahanFlag = "yes";
            //         documentsMapper[roadTaxPaidIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[roadTaxPaidIndex].vaahanFlag = "no";
            //         roadTaxNoMatch = true;
            //         documentsMapper[roadTaxPaidIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     if (isValidDate(vahanWebsite["MV Tax upto:"])) {
            //         if (formatDate(vahanWebsite["MV Tax upto:"]) == documentsMapper[roadTaxDateValidityIndex].value) {
            //             documentsMapper[roadTaxPaidIndex].vaahanFlag = roadTaxNoMatch ? "no" : "yes";
            //             documentsMapper[roadTaxPaidIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
            //             documentsMapper[roadTaxDateValidityIndex].vaahanFlag = roadTaxNoMatch ? "no" : "yes";
            //             documentsMapper[roadTaxDateValidityIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
            //             resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //         }
            //         else {
            //             documentsMapper[roadTaxPaidIndex].vaahanFlag = "no";
            //             documentsMapper[roadTaxPaidIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
            //             documentsMapper[roadTaxDateValidityIndex].vaahanFlag = "no";
            //             documentsMapper[roadTaxDateValidityIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
            //             resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);

            //         }
            //     }
            // }
            //RTO NOC Issued: We will compare this with Vahan Website.
            // If vahan website data is not available then we will not show any information symbol.
            // Logic for vahan data match/green tick,
            // case 1: RC Status = “NOC Issued” and Inspection RTO NOC Issued = Yes,
            // case 2: RC Status = “Active” and Inspection RTO NOC Issued = No.
            // Rest all cases will go in mismatch.
            //Road Tax Paid:
            const rtoNoIssuedIndex = documentsMapper.findIndex(item => item.key == "rtoNocIssued");
            // let rtoNoIssuedNoMatch = false;
            // if (vahanWebsite && vahanWebsite["RC Status:"]) {
            //     if (vahanWebsite["RC Status:"].match(/NOC Issued/i) && documentsMapper[rtoNoIssuedIndex].value.match(/Yes/i)) {
            //         documentsMapper[rtoNoIssuedIndex].vaahanFlag = "yes";
            //         documentsMapper[rtoNoIssuedIndex].vahanWebsite = vahanWebsite["RC Status:"];
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[rtoNoIssuedIndex].vaahanFlag = "no";
            //         rtoNoIssuedNoMatch = true;
            //         documentsMapper[rtoNoIssuedIndex].vahanWebsite = vahanWebsite["RC Status:"];
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     if (vahanWebsite["RC Status:"].match(/Active/i) && documentsMapper[rtoNoIssuedIndex].value.match(/No/i)) {
            //         documentsMapper[rtoNoIssuedIndex].vaahanFlag = rtoNoIssuedNoMatch ? "no" : "yes";
            //         documentsMapper[rtoNoIssuedIndex].vahanWebsite = vahanWebsite["RC Status:"];
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            //     else {
            //         documentsMapper[rtoNoIssuedIndex].vaahanFlag = "no";
            //         documentsMapper[rtoNoIssuedIndex].vahanWebsite = vahanWebsite["RC Status:"];
            //         resetInspectionDetailByKeyConnect("documentsMapper", documentsMapper);
            //     }
            // }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parivahanWebsite, vahanWebsite, vahanSms]);

    useEffect(() => {
        if (appointmentId) {
            getVaahanData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId]);

    useEffect(() => {
        if (selectedVersion <= -1) {
            if(!inspectionStatus) {
                fetchInspectionDetailConnect(carId);
            }
        }
        else {
            fetchInspectionDetailConnect(carId, selectedVersion, inspectionStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carId, inspectionStatus]);
    
    useEffect(() => {
        !!isDocumentPopup && openDocumentPopup();
    }, [isDocumentPopup]);
    const getVaahanData = () => {
        // let res = getVahaanDataMoc();
        // const { detail } = res;
        // if (detail) {
        //     const { parivahan1, vahanWebsite, parseSms } = detail;

        //     processVahanData(parivahan1, vahanWebsite, parseSms);
        // }
        InspectionService.getVaahanData(appointmentId)
            .then(res => {
                const { data: { detail } } = res;
                if (detail) {
                    const { parivahan1, vahanWebsite, parseSms } = detail;
                    processVahanData(parivahan1, vahanWebsite, parseSms);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const processVahanData = (parivahanWebsite, vahanWebsite, vahanSms) => {
        const parivahanObj = {};
        const vahanObj = {};
        const vahanSmsObj = {};
        try {
            if (parivahanWebsite) {
                // parivahanWebsite.registrationNumber = parivahanWebsite["Registration No:"];
                parivahanWebsite.fuelType = parivahanWebsite["Fuel Type:"];
                parivahanWebsite.engineNumber = parivahanWebsite["Engine No:"];
                // parivahanWebsite.fitnessUpto = parivahanWebsite["Fitness Upto:"];
                // parivahanWebsite.registrationYear = parivahanWebsite["Registration Date:"] ? parivahanWebsite["Registration Date:"].split("-")[2] : "";
                // parivahanWebsite.registrationMonth = parivahanWebsite["Registration Date:"] ? parivahanWebsite["Registration Date:"].split("-")[1] : "";
                // parivahanWebsite.customerName = parivahanWebsite["Owner Name:"];
                parivahanWebsite.insuranceExpiry = parivahanWebsite["Insurance Upto:"];
            }

            if (vahanWebsite) {
                // vahanWebsite.registrationNumber = vahanWebsite["Registration No:"];
                // vahanWebsite.fitnessUpto = vahanWebsite["Fitness/REGN Upto:"];
                // vahanWebsite.registrationYear = vahanWebsite["Registration Date:"] ? vahanWebsite["Registration Date:"].split("-")[2] : "";
                // vahanWebsite.registrationMonth = vahanWebsite["Registration Date:"] ? vahanWebsite["Registration Date:"].split("-")[1] : "";
                vahanWebsite.fuelType = vahanWebsite["Fuel Type:"];
                // vahanWebsite.roadTaxPaid = vahanWebsite[""]
                // vahanWebsite.isUnderHypothecation = vahanWebsite["hypothecation"];
                // vahanWebsite.customerName = vahanWebsite["Owner Name:"];
            }
            if (vahanSms && vahanSms.length > 0) {
                // vahanSms.noOfOwners = vahanSms[0]["ownerNo"];
                // vahanSms.registrationNumber = vahanSms[0]["registrationNumber"];
                // vahanSmsObj.fitnessUpto = vahanSms[0][""];
                // vahanSmsObj.fuelType = vahanSms[0].[""];
                // vahanSms.isUnderHypothecation = vahanSms[0]["hypothecation"];
                // vahanSms.customerName = vahanSms[0]["ownerName"];
            }
        }
        catch (e) {
            console.log("here", e);
        }
        resetInspectionDetailByKeyConnect("parivahanWebsite", parivahanWebsite);
        resetInspectionDetailByKeyConnect("vahanWebsite", vahanWebsite);
        resetInspectionDetailByKeyConnect("vahanSms", vahanSms);

    };

    const openDocumentPopup = () => {
        setIsDocumentPopupOpen(true);
    };
    const closeDocumentPopup = () => {
        setIsDocumentPopupOpen(false);
        window.location.reload();
    };
    
    if (isReviewSubmitting)
        return <Loader />;

    return (
        <div>
            {
                !isLoading && !error && <>
                    <div className={styles.fixedHeader} >
                        <InspectionHeader history={history} />
                        <InspectionReviewInfo inputEl={inputEl} docRef={docRef} intRef={intRef} sumRef={sumRef} />
                    </div>
                    <div ref={docRef} id="section-1">
                        <InspectionReviewRc />
                    </div>
                    <InspectionReviewInsurance />
                    <InspectionReviewCarDetails />
                    <div ref={inputEl} id='section-2'>
                        <InspectionReviewExterior />
                    </div>
                    <InspectionReviewTyres />
                    <div ref={intRef} id='section-3'>
                        <InspectionReviewInterior />
                    </div>
                    {
                        !!reInspectionEmailLog.length && (
                            <ReInspectionSentMail reInspectionEmailLog={reInspectionEmailLog} />
                        )
                    }
                    {
                        (version > 0 || version === "current") && <div className={styles.reInspection}><ReInspection mailFeature={true} /></div>
                        // <div className={styles.reInspection}><ReInspection mailFeature={true} /></div>
                    }
                    {/*<ErrorList />*/}
                    <div>
                        <AdditionalImages />
                    </div>
                    {rejectionData.length > 0 && (
                        <RejectionTable data={rejectionData} />
                    )}
                    <div ref={sumRef} id='section-4'>
                        <ErrorList setRejectionDataList={setRejectionDataList} />
                    </div>
                    <div>
                        <InspectionReviewFooter history={history} rejectionDataList={rejectionDataList} setIsReviewSubmitting={setIsReviewSubmitting}/>
                    </div>
                </>
            }
            <DataInformationMessage
                isLoading={isLoading}
                data={[1]}
                error={error}
            />
            <Popup isOpen={isDocumentPopupOpen} closeOnOutside={false} contentStyles={{maxHeight:"700px"}}>
                <CustomerDocumentPopup 
                    onClose={closeDocumentPopup}
                    inspectionDetails={inspectionDetails}
                />
            </Popup>
        </div>

    );
};

export default InspectionReview;
